<template>
    <div></div>
</template>

<script>
import { setLocal } from "../../../static/js/publicMethod"
export default {
  data() {
    return {};
  },
  created() {
    let { targetUrl,authToken } = this.$route.query
    console.log(this.$router.query,"[[[[[[[[[[[[[")
    if(targetUrl&&authToken) {
        setLocal("token",decodeURI(authToken))
        window.location.href = targetUrl
    }
  },
};
</script>