
//设置本地字段
export const setLocal = function(key,value) {
  localStorage.setItem(key,value)
}
//获取本地字段
export const getLocal = function(key) {
  let res = localStorage[key]
  return res
}
//删除本地字段
export const removeLocal = function(key) {
  localStorage.removeItem(key)  
}